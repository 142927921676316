const isPlainObject = (value: unknown): value is Object =>
  typeof value === 'object' && value !== null && !Array.isArray(value);

export type DeNullify<T> = T extends null
  ? undefined
  : T extends { [key: string]: any }
  ? { [K in keyof T]: DeNullify<T[K]> }
  : T;

export const deNullify = <T>(src: T): DeNullify<T> => {
  if (src === null) {
    return undefined as DeNullify<T>;
  }
  if (isPlainObject(src)) {
    const obj = Object.create(null);
    for (const [key, value] of Object.entries(src)) {
      obj[key] = deNullify(value);
    }
    return obj;
  }
  return src;
};

/**
 * Polls the result until conditions are met or the timeout exceed.
 */
export const poll = <T extends any>({
  fn,
  condition,
  interval = 1000,
  timeout = 10000,
}: {
  fn(): Promise<T>;
  condition(data: T): boolean;
  interval?: number;
  timeout?: number;
}): Promise<T> => {
  const START = Date.now();

  let lastResult: T | null = null;
  let lastError: Error | null = null;

  return new Promise(async function run(resolve, reject) {
    const tryToScheduleRun = () => {
      if (Date.now() - START < timeout) {
        setTimeout(run, interval, resolve, reject);
      } else {
        if (lastError && !lastResult) {
          return reject(lastError);
        }

        return resolve(lastResult!);
      }
    };

    try {
      lastResult = await fn();

      if (condition(lastResult)) {
        resolve(lastResult);
      } else {
        tryToScheduleRun();
      }
    } catch (e) {
      lastError = e as any;
      tryToScheduleRun();
    }
  });
};
